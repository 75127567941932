import React, { useState, useEffect, Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { appTheme } from "common/src/theme/app";
import { GlobalStyle, AppWrapper } from "common/src/containers/App/app.style";
import { ResetCSS } from "common/src/assets/css/style";
import Navbar from "common/src/containers/App/Navbar";
import Footer from "common/src/containers/App/Footer";

import Container from "../../../common/src/components/UI/Container";

import { DrawerProvider } from "common/src/contexts/DrawerContext";

import "@redq/reuse-modal/es/index.css";
import SEO from "../components/seo";

function getSize() {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth
  };
}

function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setWindowSize(getSize());
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
}

export default () => {
  const size = process.browser && useWindowSize();

  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <SEO
          title="Haveacoffeeshop | shop where you are"
          metaDescription="A digital coffeeshop finder and online product ordering service brought to mobile devices."
        />
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar
                deactivated
                extraLinks={[
                  { title: "Home", to: "/" },
                  { title: "Impressum", to: "/impressum" }
                ]}
              />
            </DrawerProvider>
          </Sticky>
          <Container>
            <h1>Datenschutzerklärung</h1>
            <h3>
              Sie können unsere Seite besuchen, ohne Angaben zu Ihrer Person zu
              machen. Personenbezogene Daten werden nur erhoben, wenn Sie uns
              diese im Rahmen Ihres Besuchs unseres Internetauftritts freiwillig
              mitteilen.
            </h3>
          </Container>
          <Footer />
        </AppWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
